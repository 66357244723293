var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper pending-items-page-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _vm._m(1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Downline Rep Logins")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-table-card"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Rep Name")]), _c('th', [_vm._v("State")]), _c('th', [_vm._v("Rep Phone")]), _c('th', [_vm._v("Reg Date")]), _c('th', [_vm._v("Last Enrollment")]), _c('th', [_vm._v("# Members")]), _c('th', [_vm._v("# Logins")]), _c('th', [_vm._v("Last Login")])])]), _c('tbody', [_c('tr', [_c('td', [_c('span', {
    staticClass: "rep-name"
  }, [_vm._v("Davis Kobayashi")]), _c('span', {
    staticClass: "rep-code"
  }, [_vm._v("(DKHI4963)")])]), _c('td', [_c('span', {
    staticClass: "state"
  }, [_vm._v("HI")])]), _c('td', [_c('span', {
    staticClass: "rep-phone"
  }, [_vm._v("808-216-7777")])]), _c('td', [_c('span', {
    staticClass: "reg-date"
  }, [_vm._v("03/16/2018")])]), _c('td', [_c('span', {
    staticClass: "last-enrollment"
  }, [_vm._v("00/00/0000")])]), _c('td', [_c('span', {
    staticClass: "member-num"
  })]), _c('td', [_c('span', {
    staticClass: "logins-num"
  }, [_vm._v("1")])]), _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("03/26/2018")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "rep-name"
  }, [_vm._v("Davis Kobayashi")]), _c('span', {
    staticClass: "rep-code"
  }, [_vm._v("(DKHI4963)")])]), _c('td', [_c('span', {
    staticClass: "state"
  }, [_vm._v("HI")])]), _c('td', [_c('span', {
    staticClass: "rep-phone"
  }, [_vm._v("808-216-7777")])]), _c('td', [_c('span', {
    staticClass: "reg-date"
  }, [_vm._v("03/16/2018")])]), _c('td', [_c('span', {
    staticClass: "last-enrollment"
  }, [_vm._v("00/00/0000")])]), _c('td', [_c('span', {
    staticClass: "member-num"
  })]), _c('td', [_c('span', {
    staticClass: "logins-num"
  }, [_vm._v("1")])]), _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("03/26/2018")])])])])])]), _c('div', {
    staticClass: "table-pagination mt-3"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "active-table",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("2")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("3")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("4")])]), _c('li', [_c('a', {
    staticClass: "next-highlight",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Next")])])])])]);
}]

export { render, staticRenderFns }