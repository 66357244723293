<template>
    <div class="page-wrapper pending-items-page-wrap">
        <div class="container-fluid">
            <breadcrumb :items="breadcrumbItems" />
            <div class="page-title">
                <h1>View Downline Rep Logins</h1>
            </div>
            
            <div class="new-table-card">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Rep Name</th>
                                <th>State</th>
                                <th>Rep Phone</th>
                                <th>Reg Date</th>
                                <th>Last Enrollment</th>
                                <th># Members</th>
                                <th># Logins</th>
                                <th>Last Login</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="rep-name">Davis Kobayashi</span>
                                    <span class="rep-code">(DKHI4963)</span>
                                </td>
                                <td>
                                    <span class="state">HI</span>
                                </td>
                                <td>
                                    <span class="rep-phone">808-216-7777</span>
                                </td>
                                <td>
                                    <span class="reg-date">03/16/2018</span>
                                </td>
                                <td>
                                    <span class="last-enrollment">00/00/0000</span>
                                </td>
                                <td>
                                    <span class="member-num"></span>
                                </td>
                                <td>
                                    <span class="logins-num">1</span>
                                </td>
                                <td>
                                    <span class="last-login">03/26/2018</span>
                                </td>
                            </tr>
                            
                            <tr>
                                <td>
                                    <span class="rep-name">Davis Kobayashi</span>
                                    <span class="rep-code">(DKHI4963)</span>
                                </td>
                                <td>
                                    <span class="state">HI</span>
                                </td>
                                <td>
                                    <span class="rep-phone">808-216-7777</span>
                                </td>
                                <td>
                                    <span class="reg-date">03/16/2018</span>
                                </td>
                                <td>
                                    <span class="last-enrollment">00/00/0000</span>
                                </td>
                                <td>
                                    <span class="member-num"></span>
                                </td>
                                <td>
                                    <span class="logins-num">1</span>
                                </td>
                                <td>
                                    <span class="last-login">03/26/2018</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="table-pagination mt-3">
                    <ul>
                        <li><a href="#" class="active-table">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#" class="next-highlight">Next</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RepLogins',
    data() {
		return {
			breadcrumbItems: [{ title: "Rep Logins", active: true }],
		};
	},
}
</script>
<style src="../../assets/css/loginsnotlogins.css"></style>
